
<template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
       width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
       preserveAspectRatio="xMidYMid meet">
    <metadata>
      Created by potrace 1.14, written by Peter Selinger 2001-2017
    </metadata>
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
       fill="#FFFFFF" stroke="none">
      <path d="M765 4033 c-47 -24 -86 -64 -106 -108 -18 -38 -19 -97 -19 -1363 0
-1173 2 -1327 16 -1360 19 -47 58 -88 106 -113 36 -18 85 -19 1795 -19 1690 0
1760 1 1798 19 49 23 89 64 109 113 14 33 16 187 16 1358 0 1171 -2 1325 -16
1358 -19 47 -58 88 -106 113 -36 18 -85 19 -1800 19 -1586 -1 -1766 -2 -1793
-17z m3505 -1473 l0 -1280 -1710 0 -1710 0 0 1280 0 1280 1710 0 1710 0 0
-1280z"/>
      <path d="M1280 2025 l0 -105 855 0 855 0 0 105 0 105 -855 0 -855 0 0 -105z"/>
      <path d="M3200 2025 l0 -105 320 0 320 0 0 105 0 105 -320 0 -320 0 0 -105z"/>
      <path d="M1710 1600 l0 -110 425 0 425 0 0 110 0 110 -425 0 -425 0 0 -110z"/>
      <path d="M2774 1697 c-3 -8 -3 -56 -2 -108 l3 -94 318 -3 317 -2 0 110 0 110
-315 0 c-253 0 -317 -3 -321 -13z"/>
    </g>
  </svg>
</template>
